<template>
	<div>
		<!-- 锚点导航 -->
		<asideBar />
		<div class="main-content flex-fill"
			:style="{ 'margin-left': $store.state.isMobile ? '0px' : $store.state.collapse ? '200px' : '60px' }">
			<div class="container customize-width px-md-5 mt-4">
				<div class="content">
					<div class="content-wrap">
						<div class="content-layout">
							<nav class="text-sm text-muted mb-4">
								<i class="iconfont icon-home mr-2"></i><a class="crumbs"
									@click="clickJump('/')">首页</a><i class="px-2">/</i><span>所有类别</span>
							</nav>
							<!--设计  -->
							<div v-for="item of getDataList" :key="item.id">
								<div class="block-header my-3 my-md-4">
									<h4 class="block-title">{{ item.name }}</h4>
									<div class="flex-fill"></div>
									<!-- <a @click="goZhuYe">进入主页<i class="iconfont icon-arrow-r-m"></i></a> -->
								</div>
								<div class="card-grid">
									<div class="category-card" v-for="item2 in item.nav" :key="item2.id">
										<div class="category-body">
											<a class="category-content"
												@click="clickJump('/details?id=' + item2.id + '&cid=' + item2.category_id)">
												<div class="category-ico">
													<img :src="imageESrc + item2.image"
														style="width: 30px;height: 30px;" />
												</div>
												<div class="overflowClip_1">
													<strong>{{ item2.title }}</strong>
												</div>
											</a>
										</div>
									</div>
								</div>
							</div>
							<!-- === -->
						</div>
					</div>
				</div>
				<!-- 弹框 -->
				<asideDrawer />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getNav
	} from '@/api/http'
	import {
		API_URL
	} from '@/utils/request.js'
	import asideBar from '@/views/layout/components/aside'
	import asideDrawer from '@/views/layout/components/asideDrawer'
	export default {
		components: {
			asideBar,
			asideDrawer
		},
		data() {
			return {
				getDataList: [],
				imageESrc: API_URL,
				hm: {
					theme: {
						mainColor: '#2254f4',
						mainColorActive: '#1f4bdb',
						mainColor10: 'rgba(34, 84, 244, .1)',
						mainColor20: 'rgba(34, 84, 244, .2)',
						mainColor30: 'rgba(34, 84, 244, .3)',
						mainColor50: 'rgba(34, 84, 244, .5)',
						mainColor60: 'rgba(34, 84, 244, .6)'
					},
					curDomain: 'https://huamao.vip/',
					userInfo: {},
					link: {
						passApiUrl: 'https://api.colorpuss.com/user/auth/pass/?callback_url=https%3A%2F%2Fhuamao.vip%2F&auth_callback_url=https%3A%2F%2Fhuamao.vip%2Fapi%2Fuser%2FsaveCertificate%2F&auth_page_url=https%3A%2F%2Fwww.colorpuss.com%2Flogin%2F',
						contact: '/about/#l-contact',
						qqCs: 'http://wpa.qq.com/msgrd?v=3&amp;uin=1147979142&amp;site=qq&amp;menu=yes',
						search: '/s/',
						searchAssociate: 'https://huamao.vip/api/associate/search/'
					}
				}
			}
		},

		created() {
			this.getList()
		},

		methods: {
			clickJump(val) {
				this.$router.push(val)
			},
			async getList() {
				const res = await getNav()
				this.getDataList = res.data.data
				console.log('-----res-------', JSON.stringify(res.data.data))
			},
			goZhuYe() {}
		}
	}
</script>

<style>
	@import url('../css/bootstrap.css');
	@import url('../css/style.css');

	.category-card {
		cursor: pointer;
	}
</style>